import classNames from "classnames";
import React from "react";
import Switch from "react-switch";
import { getSwitchOffColor } from "../lib/styleFunctions";
import { useSelector } from "react-redux";
import { blurCalendar } from "../services/appFunctions";

interface DefaultSwitchProps {
  disabled?: boolean
  isChecked: boolean
  onChange?: (checked: boolean) => void
  additionalClassNames?: string
  id?: string
  tabIndex?: number
  lightModeOverride?: boolean
}

export default function DefaultSwitch({
  disabled = false,
  isChecked,
  onChange,
  additionalClassNames,
  id,
  tabIndex,
  lightModeOverride,
}: DefaultSwitchProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  return (
    <Switch
      tabIndex={tabIndex || -1}
      checked={isChecked}
      onChange={(val) => {
        if (onChange) {
          onChange(val);
        }
        blurCalendar();
      }}
      onColor="#86d3ff"
      offColor={getSwitchOffColor(isDarkMode && !lightModeOverride)}
      onHandleColor="#2693e6"
      handleDiameter={16}
      uncheckedIcon={false}
      checkedIcon={false}
      disabled={disabled}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={12}
      width={25}
      className={classNames("react-switch", isChecked ? "" : "react-switch-hover", additionalClassNames || "")}
      id={id}
    />
  );
}
