import type { Styles as ModalStyles } from "react-modal";
import StyleConstants, { getModalBackgroundColor, MODAL_BLUR, MODAL_BORDER_RADIUS } from "../services/globalVariables";
import { getDefaultModalBorder } from "./styleFunctions";

export const MODAL_TYPES = {
  FIND_FREE_TIMES: "FIND_FREE_TIMES",
  METRICS_IS_AVAILABLE_MODAL: "METRICS_IS_AVAILABLE_MODAL",
  REFER_BOOST: "REFER_BOOST",
} as const;

/**
 * The general behavior of modals with the same index is that the most recent modal gets rendered on top.
 * This is usually fine, but there are some modals that should always take priority over others.
 * The z-index values here should be applied to the modal's overlay. The modal's content's z-index doesn't
 * need this since it's a child of the overlay.
 */
export const MODAL_OVERLAY_Z_INDEXES = {
  /**
   * Useful for cases like flyout previews and small option modals that only render above the app.
   * Do not use this if the modal should render above other modals.
   * */
  LOW_PRIORITY: 5,
  DEFAULT: 100,
  /* Audit needs to be above calendar scrollbar */
  AUDIT: 101,
  /** The paywall should be rendered on top of almost everything. */
  PAYWALL: 105,
  /** The payment modal should always be above the paywall. */
  PAYMENT: 110,
  /** The option to join a team plan should always render on top of the paywall. */
  TEAM_PLAN_INVITE: 120,
} as const;

export const MODAL_CONTENT_BOX_SHADOW = "0 0px 30px 3px rgba(0, 0, 0, 0.2)";

export function determineDefaultModalStyle(
  isDarkMode?: boolean,
  isTeamPlan = false,
  overrides: ModalStyles = {},
): ModalStyles {
  return {
    overlay: {
      position: "fixed",
      inset: 0,
      backgroundColor: "rgba(0, 0, 0, 0.70)",
      zIndex: MODAL_OVERLAY_Z_INDEXES.DEFAULT,
      overflow: isTeamPlan ? "hidden" : "",
      ...(overrides.overlay ? overrides.overlay : {}),
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: MODAL_BORDER_RADIUS,
      boxShadow: MODAL_CONTENT_BOX_SHADOW,
      zIndex: 5,
      backgroundColor: getModalBackgroundColor(isDarkMode),
      color: isDarkMode
        ? StyleConstants.darkModeModalTextColor
        : StyleConstants.defaultFontColor,
      maxHeight: "90vh",
      backdropFilter: MODAL_BLUR,
      WebkitBackdropFilter: MODAL_BLUR,
      padding: isTeamPlan ? "0" : "20px",
      overflow: isTeamPlan ? "hidden" : "auto",
      border: isDarkMode ? getDefaultModalBorder(isDarkMode) : "1px solid transparent",
      ...(overrides.content ? overrides.content : {}),
    },
  };
}

export function getSchedulingAssistantModalStyles(isDarkMode?: boolean) {
  return determineDefaultModalStyle(isDarkMode, false, {
    content: {
      width: "calc(100% - 96px)",
    },
  });
}
