export const ACCOUNT_STATE_WAIT_LIST = "waitlist";
export const ACCOUNT_STATE_AWAITING_ONBOARDING = "awaiting_onboarding";
export const ACCOUNT_STATE_ONBOARDING = "onboarding";

export const ACCOUNT_STATE_PAYING = "paying";
export const ACCOUNT_STATE_PREPAID = "prepaid";
export const ACCOUNT_STATE_FREEMIUM = "freemium";
export const ACCOUNT_STATE_TRIAL = "trial";
export const ACCOUNT_STATE_BACKSTAGE = "backstage";
export const ACCOUNT_STATE_NEW_USER = "new_user";
export const ACCOUNT_STATE_REFERRED = "referred";
export const ACCOUNT_STATE_VIP = "vip";

// cancelled states below
export const ACCOUNT_STATE_ABANDONED = "abandoned";
export const ACCOUNT_STATE_DID_NOT_BUY = "did_not_buy";
export const ACCOUNT_STATE_CANCELLED = "cancelled";
export const ACCOUNT_STATE_CANCELLED_BUT_UPDATE = "cancelled_keep_update";
export const ACCOUNT_STATE_TRIAL_CHURNED = "trial_churned";
export const ACCOUNT_STATE_TRIAL_ENDED = "trial_ended";
// cancelled states above


export const LOCAL_TIME_ZONE = "Time zone based on location";

export const MONTHLY = "MONTHLY_PLAN";
export const YEARLY = "YEARLY_PLAN";

export const PRODUCT_HUNT_PROMOTION = "ph_JWKPsboZWKPf6J";
export const EARLY_ACCESS_PROMOTION = "ea_JdPkIeOohUA6ez";
export const LEGACY_YC_PROMOTION = "bf_KDfOg13Ngdxzz8";
export const STANDARD_YC_PROMO = "yc"; // 50% first 6 months
export const ADMINS_DAY_PROMO = "admins_day"; // 50% off first year
export const HUSTLE_FUND_PROMOTION = "hf_KXcbGWt3PkIeoZ";
export const WISHU_PROMOTION = "wi_yoNCEtRANTIcOU";
export const FAILSAFE_PROMOTION = "fs_icKITicUSEGImP";
export const TYPEFORM_LINKS = {
  NORMAL: "https://vimcal.typeform.com/aloha?utm_source=web-login&ref=web-login",
  MAESTRO: "https://vimcal.typeform.com/maestro?utm_source=web-login&ref=web-login",
} as const;

// spec for slots copy:
// https://docs.google.com/document/d/1FMd6ubqKJ2vbGgnbRRzPExF8t2hhb8nwsgQQ5fB4ucM/edit#
export const SLOTS_STYLE = "slots_style";
export const SLOTS_PLAIN_TEXT = "plaintext";
export const SLOTS_PLAIN_TEXT_URL = "plaintext_url";
export const SLOTS_RICH_TEXT = "rich_text";
export const LINK_ALONE = "url_only";
// slots copy variables
export const SLOTS_PLAIN_TEXT_COPY = "plaintext_copy";
export const SLOTS_PLAIN_TEXT_URL_COPY = "plaintext_url_copy";
export const SLOTS_PLAIN_TEXT_URL_LINK_COPY = "plaintext_url_link_copy";
export const SLOTS_RICH_TEXT_COPY = "rich_text_copy";
export const FOCUS_MODE_HOTKEY = "0";
export const HOLD_TITLE = "holds_title";
export const HOLD_COLOR_ID = "holds_color_id";
export const ADD_ATTENDEE_TO_HOLDS = "should_add_attendees_to_holds";
export const PAID_ACCESS_REQUIRED = "paid_access_required";
export const PARTNER_WITH_US_URL = "https://www.vimcal.com/partner";

export const RIGHT_PANEL_CONTENT_WIDTH = 325;

export const POPULAR_TIME_ZONE = "popular_time_zone"; // key we use to get user's popular time zone from backend

export const SPECIAL_TAGS_TYPE = {
  SOLO: "vimcal_solo",
  EXTERNAL: "vimcal_external",
  INTERNAL: "vimcal_internal",
  RECURRING: "vimcal_recurring",
} as const;

// used for pasting slots
export const SLOTS_ELEMENT = {
  LOCATION: "location",
  DESCRIPTION: "description",
} as const;

export const VIMCAL_FTF_DESIGN = {
  DARK_MODE: "https://vimcal-app-images.s3.amazonaws.com/VIMCAL-FTF-DARK.png",
  LIGHT_MODE: "https://vimcal-app-images.s3.amazonaws.com/VIMCAL-FTF-LIGHT.png",
} as const;

export const FEEDBACK_TYPE = {
  FEEDBACK: "feedback",
  BUG_REPORT: "bug_report",
  FEATURE_REQUEST: "feature_request",
  QUESTION: "question",
} as const;

export const APP_ELEMENT_ID = {
  SETTINGS_MODAL: "settings_modal-id",
} as const;

export const ALL_CANCELLED_STATE = [
  ACCOUNT_STATE_CANCELLED,
  ACCOUNT_STATE_DID_NOT_BUY,
  ACCOUNT_STATE_ABANDONED,
  ACCOUNT_STATE_CANCELLED_BUT_UPDATE,
  ACCOUNT_STATE_TRIAL_CHURNED,
  ACCOUNT_STATE_TRIAL_ENDED,
] as const;

export const ENVIRONMENTS = {
  DEV: "dev",
  DOGFOOD: "dogfood",
  LOCAL: "local",
  PRODUCTION: "production",
  STAGING: "staging",
  TESTING: "testing",
} as const;

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  || "pk_test_JfkJNk8SSeqeBrfmHhKBh4K6";

export const CALENDAR_PROVIDERS = {
  GOOGLE: "google",
  OUTLOOK: "outlook",
} as const;

export const ERROR_TYPE_PAYMENT = "paymentError";
export const ERROR_TYPE_TEAM_PLAN = "teamPlanError";

const PROFILE = "Profile";
const CALENDAR_SETTINGS = "My Calendars";
const THEME_SETTING = "Theme";
const PAINTER_SETINGS = "Tags";
const CONFERENCING = "Conferencing";
const GUEST_PERMISSIONS = "Guest Permissions";
const CONTACT_GROUPS = "Contact Groups";
const TIME_ZONE = "Time Zone";
const PREFERENCES = "General";
const MENU_BAR = "Up Next";
const BILLING = "Billing";
const TEAM = "Team";
const SLOT = "Slots";
const COLOR_LABEL = "Color Labels";
const INTERNAL_DOMAINS = "Internal Domains";
const ADVANCED = "Advanced";

export const APP_SETTINGS = {
  SLOTS: SLOT,
  OPEN_SETTINGS_MODAL: "OPEN_SETTINGS_MODAL",
  PROFILE: PROFILE,
  CALENDAR_SETTINGS: CALENDAR_SETTINGS,
  THEME_SETTING: THEME_SETTING,
  PAINTER_SETINGS: PAINTER_SETINGS,
  CONFERENCING: CONFERENCING,
  GUEST_PERMISSIONS: GUEST_PERMISSIONS,
  CONTACT_GROUPS: CONTACT_GROUPS,
  TIME_ZONE: TIME_ZONE,
  PREFERENCES: PREFERENCES,
  MENU_BAR: MENU_BAR,
  BILLING: BILLING,
  TEAM: TEAM,
  COLOR_LABEL: COLOR_LABEL,
  INTERNAL_DOMAINS: INTERNAL_DOMAINS,
  ADVANCED: ADVANCED,
} as const;

export const CONFERENCING_SETTINGS_ID = {
  ZOOM: "settings-zoom-id",
  GOOGLE: "settings-google-id",
  MICROSOFT: "settings-microsoft-id",
  PHONE: "settings-phone-id",
  CUSTOM: "settings-custom-id",
  OTHER: "settings-other-id",
} as const;

export const PREFERENCES_SETTINGS_ID = {
  THEME: "settings-theme-id",
  TIME_ZONE: "settings-time-zone-id",
  DISPLAY: "settings-display-id",
  EVENTS: "settings-events-id",
  COLOR_LABEL_ID: "settings-color-label-id",
  INTERNAL_DOMAINS: "settings-internal-domains-id",
} as const;

export const BACKEND_AVAILABILITY_SETTINGS_NAMES = {
  SLOTS_SHOW_ALL_TIMEZONES: "show_all_time_zones",
} as const;

export const BACKEND_SETTINGS_NAMES = {
  ANCHOR_TIME_ZONES: "anchor_time_zones",
  AUTO_EMOJIS: "auto_emojis",
  AUTO_MERGE_EVENTS: "auto_merge_events",
  CALENDAR_VIEW: "calendar_view",
  CONTACT_GROUPS: "contact_groups",
  CUSTOM_CONFERENCING_NAME: "custom_conferencing_name",
  CUSTOM_CONFERENCING_URL: "custom_conferencing_url",
  DARK_MODE: "dark_mode",
  DATE_FORMAT: "date_format",
  DEFAULT_CONFERENCING_OPTION: "default_conferencing_option",
  DEFAULT_MEETING_DURATION: "default_meeting_duration",
  DEFAULT_PHONE_OPTION: "default_phone_option",
  DEFAULT_ZOOM_MODE: "default_zoom_mode",
  DIM_PAST_EVENTS: "dim_past_events",
  GUEST_PERMISSION_MODIFY_EVENTS: "guest_permission_modify_events",
  GUEST_PERMISSION_INVITE_OTHERS: "guest_permission_invite_others",
  GUEST_PERMISSION_SEE_GUEST_LIST: "guest_permission_see_guest_list",
  HIDE_BUSY_COLUMN: "hide_busy_column",
  HIDE_DEFAULT_SIGNATURE: "hide_default_signature",
  HIDE_OOO_COLUMN: "hide_ooo_column",
  HIDE_WEEKENDS: "hide_weekends",
  LOCAL_TIME_ZONE: "local_time_zone",
  MATCH_OS_SCHEME_DESKTOP: "match_os_scheme_desktop",
  MENU_BAR_CALENDARS: "menu_bar_calendars",
  MILITARY_TIME: "military_time",
  MOBILE_THEME: "mobile_theme",
  MOBILE_WIDGET_SETTING: "mobile_widget_setting",
  ORDERED_TIME_ZONES: "ordered_time_zones",
  RECENT_CONTACTS: "recent_contacts",
  SHOW_DECLINED_EVENTS: "show_declined_events",
  SMART_TAGS: "smart_tags", // Not actually in user.settings or master_account.settings
  START_OF_WEEK: "start_of_week",
  TIME_ZONE_OVERRIDE: "time_zone_override",
  TRIPS: "trips",
  USE_TIME_ZONE_OVERRIDE: "use_time_zone_override",
  WEEK_NUMBER: "week_number",
  WELCOME_TOUR_DISMISSED: "welcome_tour_dismissed",
  WORK_HOURS: "work_hours",
  HIDE_WORK_LOCATION_EVENTS: "hide_work_location_events",
  COLOR_LABELS: "color_labels",
  DEFAULT_SELF_AS_SENDER: "default_send_email_as",
  INTERNAL_DOMAINS: "internal_domains",
  USERNAME: "username",
} as const;

export const WINBACK_PROMO_CODES = [
  "wb_127ff410aa3fabb5154d93b44b418f35",
  "wb_6ccefad647c692ccebf215e2980bf39f",
] as const;

export const VIMCAL_PLAN_OPTIONS = {
  VIMCAL: "vimcal",
  VIMCAL_EA: "vimcal_ea",
} as const;

export const VIMCAL_PLAN_COST = {
  VIMCAL: 15,
  VIMCAL_EA: 75,
} as const;

export const REACT_ATTENDEE_SELECT_LOCATION = {
  GROUP_VOTE_DETAIL_PAGE_LOCATION: "groupVoteDetailPage",
  AVAILABILITY: "availability",
  SIDE_MENU_BAR: "sideMenuBar",
  REFER_MENU: "referMenu",
  EVENT_FORM: "eventForm",
  PERSONAL_LINK_EVENT_FORM: "personalLinkEventForm",
  EMAIL: "email",
  FORWARD_OUTLOOK: "forwardOutlook",
  TEAM_ADD_SEATS: "teamAddSeats",
  GROUP_VOTE_INVITE_PEOPLE_MODAL: "groupVoteInvitePeopleModal",
} as const;

export const UI_DETECT_WAKE_UP = {
  IS_DIFFERENT_MINUTE: "ui-isDifferentMinute",
  WAKE_UP: "ui-wakeup",
  IS_DIFFERENT_DAY: "ui-isDifferentDay",
  IS_DIFFERENT_WEEK: "ui-isDifferentWeek",
} as const;

export const SYNC_DETECT_WAKE_UP = {
  IS_DIFFERENT_MINUTE: "sync-isDifferentMinute",
  WAKE_UP: "sync-wakeup",
  INITIAL_SYNC: "sync-initialSync",
  IS_DIFFERENT_DAY: "sync-isDifferentDay",
  IS_DIFFERENT_WEEK: "sync-isDifferentWeek",
  SYNC_CURRENT_VIEW: "sync-currentView",
  SYNC_TODAY: "sync-today",
} as const;
